
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindAllCommodityTypes } from '@/freight/application/commodity_type/search/FindAllCommodityType';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Consignee } from '@/settings/domain/consignee/Consignee';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CommoditySearch',
  components: {}
})
export default class CommoditySearch extends Vue {
  //Variable para controlar el estado de la carga
  isLoading = false;

  // Variable para almacenar la lista de commodity
  commodityList: CommodityType[] = [];

  @Inject(TYPES.COMMODITY_TYPE_FIND_ALL)
  readonly findAllCommodity!: FindAllCommodityTypes;

  @Prop({ required: true }) value!: Consignee | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (entity: CommodityType) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;

  mounted() {
    this.getCommodities();
  }

  get localValue(): Consignee | null {
    return this.value;
  }

  set localValue(value: Consignee | null) {
    this.$emit('update:value', value);
  }

  //Funcion invocada para buscar los territorios
  async getCommodities() {
    try {
      this.isLoading = true;
      this.commodityList = await this.findAllCommodity.internalExecute();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: CommodityType) {
    if (this.onInput) await this.onInput(value);
  }
}
